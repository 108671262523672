import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Message = ({ headline, title, message }) => (
  <Layout centered={true}>
    <SEO title={title} />
    <div className="max-w-3xl text-center">
      <h1 className="text-8xl gibson-bold border-b-8 pb-2 border-charcoal branch branch--tiers-1 relative mb-12">
        {headline}
      </h1>
      <p className="text-2xl">{message}</p>
    </div>
  </Layout>
);

Message.propTypes = {
  headline: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Message;
