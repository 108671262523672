import React from "react";
import Message from "../components/messageTemplate";

const NotFoundPage = () => (
  <Message
    title="404: Not found"
    headline="Oops!"
    message="This page doesn't exist!"
  />
);

export default NotFoundPage;
